<template>
  <div class="choch">
    <picture>
      <source
        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/choch_back_mob.svg`"
        media="(max-width: 1220px)"
      />
      <source
        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/choch_back.svg`"
        media="(max-width: 999999999px)"
      />
      <img class="choch__back" :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/choch_back.svg`" />
    </picture>
    <div class="content">
      <div class="choch__block">
        <div class="choch__title">{{title}}</div>
        <div class="choch__description">
         {{description}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Choch",
  props: {
    title: {
      type: String,
      default: "Что-то пошло не так"
    },
    description: {
      type: String,
      default: "Попробуйте повторить запрос позднее"
    }
  },
  metaInfo: {
    title: "Произошла ошибка",
  },
};
</script>

<style lang="scss" scoped>
.choch {
  margin-bottom: 0;
  position: relative;
  height: calc(100vh - 570px);
  min-height: 500px;
  background-image: linear-gradient(0deg, #dee2ee, #dee2ee),
    radial-gradient(
      80.48% 80.48% at 51.01% 52.72%,
      #ffffff 0%,
      #ffffff 0.01%,
      #dee2ee 64.06%,
      #dee2ee 100%
    );

    &.allPage {
      height: 100vh;
    }
  @media screen and (max-width: 1220px) {
    height: calc(100vh - 580px);
    // margin-bottom: -80px;
  }

  @media screen and (max-width: 797px) {
    height: calc(100vh - 703px);
  }

  &.inPage {
    margin-bottom: -125px;

    @media screen and (max-width: 1220px) {
      margin-bottom: -120px;
    }
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .content {
    height: 100%;
  }

  &__title {
    margin-top: auto;
    margin-bottom: 30px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 32px;
    text-align: center;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }

  &__description {
    margin-top: 30px;
    margin-bottom: auto;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #3c4242;

     @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__caption {
    max-width: 60vw;
  }

  &__block {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>